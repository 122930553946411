import Masonry from "masonry-layout";
import Plyr from "plyr";
import Swiper from "swiper";

(function ($) {
  "use strict";

  $(document).ready(function () {
    initLists();
    checkHeader();
    plyrInit();
    initBookReader();
    //audioPlayBtn();

    // dev2
    longestWord();
    swiperInit();
    // dev3
    // dev4
  }); // ready

  $(window).on("resize", function () {
    longestWord();
    // dev2
    // dev3
    // dev4
  }); // resize

  $(window).on("load", function () {
    initGridSection();
    initProjectGrid();

    // dev2
    // dev3
    // dev4
  }); // load

  function initLists() {
    $("ul, ol").each(function () {
      var $list = $(this);
      if (!$list[0].hasAttribute("class")) {
        $list.addClass("without-class");
      }
    });
  }

  function initGridSection() {
    document.querySelectorAll(".grid-section").forEach(function (gridSection) {
      var msnry = new Masonry(gridSection, {
        percentPosition: true,
        columnWidth: ".grid-sizer",
        itemSelector: ".grid-cell",
      });
      window.theatrum = {};
      window.theatrum.msnry = msnry;
      setTimeout(function () {
        msnry.layout();
      }, 500);
      setTimeout(function () {
        msnry.layout();
      }, 1500);
    });
  }

  function initProjectGrid() {
    document.querySelectorAll(".project-grid").forEach(function (gridSection) {
      var msnry = new Masonry(gridSection, {
        percentPosition: true,
        columnWidth: ".grid-sizer",
        itemSelector: ".grid-item",
      });
      setTimeout(function () {
        msnry.layout();
      }, 500);
      setTimeout(function () {
        msnry.layout();
      }, 1500);
    });
  }

  function checkHeader() {
    var $body = $("body");
    var isHeaderSubMenu = $("#header .sub-menu").length > 0;

    if (isHeaderSubMenu) {
      $body.addClass("header-with-submenu");
    }
  }

  function plyrInit() {
    var path = window.wp_helper.home_path;
    const controls = `
			<div class="plyr__controls">
				<button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
					<img src="${path}/images/ico-pause.svg" class="icon--pressed alt="image description">
					<img src="${path}/images/ico-play.svg" class="icon--not-pressed alt="image description">
					<span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
					<span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
				</button>
				<div class="plyr__progress">
					<input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
					<progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
					<span role="tooltip" class="plyr__tooltip">00:00</span>
				</div>
				<div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
				<button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
					<svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
					<svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
					<span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
					<span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
				</button>
				<div class="plyr__volume">
					<input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
				</div>
				<button type="button" class="plyr__control" data-plyr="fullscreen">
					<img src="${path}/images/ico-fullscreen.svg" alt="image description">
				</button>
			</div>
			<button type="button" class="plyr__control plyr__control--overlaid" data-plyr="play" aria-label="Play"><img src="${path}/images/ico-play.svg" alt="image description"><span class="plyr__sr-only">Play</span></button>
		`;
    const players = Array.from(
      document.querySelectorAll(
        ".media-post .video-holder, .visual-section .video-holder"
      )
    ).map(function (holdEl) {
      let videoEl = holdEl.querySelector(".video-item");
      let opts = {
        playsinline: true,
        muted: false,
        clickToPlay: true,
        fullscreen: {
          enabled: true,
        },
        invertTime: false,
        hideControls: false,
        controls,
      };

      if (!videoEl) {
        return false;
      } else {
        let player = new Plyr(videoEl, opts);

        player.on("ready", function (event) {});

        player.on("playing", function (event) {
          player.muted = false;
        });

        holdEl.plyrPlayerAPI = player;
        return player;
      }
    });
    const players2 = Array.from(
      document.querySelectorAll(".video-section .video-holder")
    ).map(function (holdEl) {
      let videoEl = holdEl.querySelector(".video-item");
      let opts = {
        playsinline: true,
        muted: false,
        clickToPlay: true,
        fullscreen: {
          enabled: true,
        },
        invertTime: false,
        hideControls: false,
      };

      if (!videoEl) {
        return false;
      } else {
        let player = new Plyr(videoEl, opts);

        player.on("ready", function (event) {});

        player.on("playing", function (event) {
          player.muted = false;
        });

        holdEl.plyrPlayerAPI = player;
        return player;
      }
    });
    // $('.video-holder .video-item').each(function(ix, video) {
    // 	var $video = $(video);
    // 	var $wrap = $video.closest('.video-holder');
    // 	// var $wrap = $video.closest('.plyr');
    // 	var player = new Plyr(video, {
    // 		// options
    // 		invertTime: false,
    // 	});

    // 	player.on('ready', function(event) {
    // 		$wrap.find('.plyr').addClass('plyr--init-hide-controls');
    // 	});

    // 	player.on('playing', function() {
    // 		$wrap.find('.plyr').removeClass('plyr--init-hide-controls');
    // 	});

    // 	// $video
    // 	// 	.on('ready', function () {
    // 	// 		$wrap.addClass('plyr--init-hide-controls');
    // 	// 	})
    // 	// 	.on('play', function () {
    // 	// 		$wrap.removeClass('plyr--init-hide-controls');
    // 	// 	});
    // });

    if ($("#audio-player").length) {
      const audioPlayer = new Plyr("#audio-player");
    }
  }

  function initBookReader() {
    var $bookReader = $("#book-reader");
    if (!$bookReader.length) {
      return;
    }

    var $container = $bookReader.parents(".book-container");
    var timeout = 2000;

    var book = $bookReader.wowBook({
      width: 1150,
      height: 570,
      handleWidth: 100,
      container: $container[0],
      centeredWhenClosed: true,
      // toolbar : "left, right, zoomin, zoomout, download",
      // controls: {},
      controls: {
        next: "#btn-book-next",
        back: "#btn-book-back",
        download: "#btn-book-download",
        zoomin: "#book-zoom-in",
        zoomout: "#book-zoom-out",
      },
      containerPadding: "30px",
      flipSound: false,
      pdf: $("#book-reader").attr("data-pdf_file"),
      onLoadPdf: function (book, page, pageIndex) {
        var currentPage = pageIndex + 1;
        var totalPages = book.pages.length;
        $(".cur-page").text(currentPage);
        $(".last-page").text(totalPages);
      },
      onShowPage: function (book, page, pageIndex) {
        // var currentPage = pageIndex + 1;
        // var totalPages = book.pages.length;
        // var w = pageIndex < 0 ? 0 : currentPage / totalPages;
        // var $progress = $('.book-progress .value');

        // if ($progress.length) {
        // 	$progress.css('width', w * 100 + '%');
        setTimeout(function () {
          var currentPage = pageIndex + 1;
          var totalPages = book.pages.length;
          var w = pageIndex < 0 ? 0 : currentPage / totalPages;
          var $progress = $(".book-progress .value");

          if ($progress.length) {
            $progress.css("width", w * 100 + "%");
          }
          checkPagerState(currentPage, totalPages);
          timeout = 0;
        }, timeout);
      },
      onZoom: function (book) {
        var $controlHold = $container.find(".reader-controls");
        var $zoom = $controlHold.find(".book-zoom-state");
        $zoom.text(book.zoomLevel * 100 + "%");
      },
      pageNumbers: false,
    });

    function checkPagerState(currentPage, totalPages) {
      currentPage = currentPage || 1;
      var $controlHold = $container.find(".reader-controls");
      var $pager = $controlHold.find(".book-state");

      if ($pager.length < 1) {
        $controlHold.append(
          '<span class="book-state"><span class="cur-page">' +
            currentPage +
            '</span>/<span class="last-page">' +
            totalPages +
            "</span>"
        );
      } else {
        $pager.find(".cur-page").text(currentPage);
        $pager.find(".last-page").text(totalPages);
      }
    }
  }

  // dev2
  function audioPlayBtn() {
    var audioLinks = document.querySelectorAll(".audio-link");
    if (audioLinks) {
      audioLinks.forEach(function (el) {
        el.addEventListener("click", function (e) {
          e.preventDefault();

          if (this.classList.contains("played")) {
            this.classList.remove("played");
          } else {
            var current = document.getElementsByClassName("played");
            if (current.length) {
              current[0].nextElementSibling.pause();
              current[0].classList.remove("played");
            }
            this.classList.add("played");
          }
          var audio = this.nextElementSibling;
          if (audio) {
            if (audio.paused) {
              audio.play();
            } else {
              audio.pause();
            }
          }
        });
      });
    }
  }

  function longestWord() {
    var titles = document.querySelectorAll(".meta-list .title");
    if (titles.length) {
      var width = [];
      titles.forEach(function (el) {
        width.push(el.offsetWidth);
      });

      var max = Math.max.apply(null, width);

      titles.forEach(function (el) {
        el.style.width = max + 1 + "px";
      });
    }
  }

  function swiperInit() {
    var sliders = [];
    const allSliders = document.querySelectorAll(".swiper-post-images");
    if (allSliders.length) {
      allSliders.forEach(function (element, index) {
        element.classList.add("swiper-post-images-" + index);
        element.children[1].classList.add("swiper-button-prev-" + index);
        element.children[2].classList.add("swiper-button-next-" + index);

        var slider = new Swiper(".swiper-post-images-" + index, {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 0,
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next-" + index,
            prevEl: ".swiper-button-prev-" + index,
          },
          lazy: {
            loadPrevNext: true,
          },
        });
        sliders.push(slider);
      });
    }
  }

  // dev3
  // dev4
})(jQuery);
