import "bootstrap";
import "bootstrap-tagsinput";
import "slick-carousel";

// General layout scripts
import "./scripts";
// Scripts for the 'live' content pages
import "./scripts-live";
// Extra functions related to search function and social media posts
import "./thmu";
