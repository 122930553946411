(function ($) {
  $(document).ready(function () {
    $(".image-block .img").each(function () {
      var $el = $(this).find("> img");
      if ($el.length > 0) {
        $(this).css("background-image", "url(" + $el.attr("src") + ")");
      }
    });

    // $('.info-box .block').matchHeight();

    var TIMEOUT = 300;
    $('<span class="fader"/>').appendTo("#header").css("opacity", 0);
    $(".btn-menu, .fader").click(function () {
      if (!$("body").hasClass("open-menu")) {
        $("body").addClass("open-menu");
        $(".fader").css("display", "block").animate(
          {
            opacity: 0.7,
          },
          TIMEOUT
        );
      } else {
        $("body").removeClass("open-menu");
        $(".fader").animate(
          {
            opacity: 0,
          },
          TIMEOUT,
          function () {
            $(this).css("display", "none");
          }
        );
      }
      return false;
    });

    var myWindow = $(window),
      myPos = myWindow.scrollTop(),
      up = false,
      newScroll;

    myWindow.scroll(function () {
      newScroll = myWindow.scrollTop();
      if (newScroll > myPos && !up) {
        $("body").removeClass("scroll-up");
        up = !up;
      } else if (newScroll < myPos && up) {
        $("body").addClass("scroll-up");
        up = !up;
      }
      myPos = newScroll;
    });

    var imagesSlideshow = $(".images-slideshow .slides");

    $(window).on("load resize", function () {
      // var winWidth = $(window).width(),
      // offset;

      // if (imagesSlideshow.length) {
      // 	offset = imagesSlideshow.parent().offset().left;
      // 	imagesSlideshow.css({
      // 		'width': winWidth,
      // 		'margin-left': -offset
      // 	});
      // }

      $(function () {
        imagesSlideshow.on("init", function (event, slick) {
          setTimeout(function () {
            imagesSlideshow.addClass("loaded");
          }, 500);
        });
        imagesSlideshow
          .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
            var $slide = $(slick.$slides.get(nextSlide)),
              $num = $slide.find(".num");

            $(slick.$slider).addClass("trans");

            if (!$num.length) {
              $slide
                .find("figcaption")
                .prepend(
                  '<span class="num">' +
                    (~~$slide.attr("data-slick-index") + 1) +
                    "/" +
                    slick.$slides.length +
                    "</span>"
                );
            }
          })
          .on("afterChange", function (event, slick, currentSlide) {
            $(slick.$slider).removeClass("trans");
          });
        imagesSlideshow.slick({
          fade: true,
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      });
    });

    $(".intro-area .logo a").on("click", function (event) {
      scrollToHomepageContent();
      event.preventDefault();
    });

    var out_flag = window.sessionStorage.getItem("thmu_first_out");
    if (out_flag && out_flag == "out") {
      $("body").addClass("first-out");
      $("body").addClass("first-out-already-has");
      $(".homepage-content").css("margin-top", 0);
    } else {
      setTimeout(function () {
        scrollToHomepageContent();
      }, 3000);
    }

    heightText();

    $(".intro-area").on("click", function () {
      scrollToHomepageContent();
    });
    // $('.info-box').on('mouseup touchend ', function(e) {
    $(".info-box").on("click", function (e) {
      var $this = $(this),
        container = $(this).find(".meta-info"),
        $href = $this.find("h2 > a").attr("href");
      if (
        !container.is(e.target) &&
        container.has(e.target).length === 0 &&
        $href
      ) {
        window.location = $href;
      }
    });
    setMainAreaTopOffset();
    initProjectTwoColumnContent();
  }); /*ready*/
  $(window).load(function () {
    setMainAreaTopOffset();

    var winHeight = $(window).height(),
      docHeight = $(document).height(),
      progressBar = $("#header progress"),
      max,
      value;

    max = docHeight - winHeight;
    progressBar.attr("max", max);
    $(window).scroll(function () {
      value = $(window).scrollTop();
      progressBar.attr("value", value);
    });
  }); /*load*/

  $(window).resize(function () {
    setTimeout(function () {
      setMainAreaTopOffset();
    }, 300);

    heightText();
  }); /*resize*/

  $(window).scroll(function () {
    var CHECK_OFFSET = getOffset();
    $(window).scrollTop() > CHECK_OFFSET
      ? $("body").addClass("scrolled")
      : $("body").removeClass("scrolled");
    scrollToHomepageContent();

    function getOffset() {
      var $header = $("#header");
      var $submenu = $("#header .sub-menu");
      var isHeader = $header.length;
      var isSubmenu = $submenu.length;

      var offset = !isHeader
        ? 0
        : isSubmenu
        ? $header.outerHeight() + $submenu.outerHeight()
        : $header.outerHeight();

      return offset;
    }
  });

  function getMainMenuWidth() {
    return $(".main-menu").outerWidth();
  }

  function getHeaderHeight() {
    return $("#header").outerHeight();
  }

  function getFooterHeight() {
    return $("#footer-new").outerHeight();
  }

  function setMainAreaTopOffset() {
    if ($(".main-area").length) {
      $(".main-area").css(
        "min-height",
        $(window).height() - getFooterHeight() + "px"
      );
      $(".main-area").css("padding-top", getHeaderHeight() + "px");
    }
  }

  function scrollToHomepageContent() {
    if (!$("body").is(".home") || $(".grid-section").length) {
      return;
    }
    var out_flag = window.sessionStorage.getItem("thmu_first_out");
    if (out_flag && out_flag == "out") {
      $("body").addClass("first-out");
      $(".homepage-content").css("margin-top", 0);
    } else {
      if (!$("body").is(".first-out")) {
        $("html,body").stop().scrollTop(0);
        disableScroll();
        window.onwheel = function () {
          return false;
        };
        $(".intro-area").animate(
          {
            top: "-100vh",
          },
          1000
        );
        $(".homepage-content").animate(
          {
            marginTop: 0,
          },
          1000,
          function () {
            $("body").addClass("first-out");
            window.sessionStorage.setItem("thmu_first_out", "out");
            enableScroll();
            window.onwheel = function () {};
          }
        );
      }
    }
  }

  function heightText() {
    $(".info-box").each(function (index, el) {
      var heightParent = $(this).outerHeight(),
        lineHeightEl = parseInt($(this).find(".block p").css("line-height")),
        padding =
          $(this).find(".block").outerHeight() -
          $(this).find(".block").height();
      (titleHeight = $(this).find("h2").outerHeight(true)),
        (metaHeight = $(this).find(".meta-info").outerHeight());
      (result = heightParent - titleHeight - metaHeight - padding),
        (remainder = result % lineHeightEl),
        (resultHeught = result - remainder);

      $(this).find(".block p").height(resultHeught);
    });
  }

  // disable scroll
  var keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1,
  };

  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  function disableScroll() {
    if (window.addEventListener)
      // older FF
      window.addEventListener("DOMMouseScroll", preventDefault, false);
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove = preventDefault; // mobile
    document.onkeydown = preventDefaultForScrollKeys;
  }

  function enableScroll() {
    if (window.removeEventListener)
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }

  function initProjectTwoColumnContent() {
    $(
      ".project-template-default .main-area .content .container-fluid"
    ).addClass("two-column-content");
  }
})(jQuery);
