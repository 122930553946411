(function ($) {
  var thmu = {};

  $(document).ready(function () {
    thmu.init();

    thmu.search_handler();

    thmu.inner_search_handler();

    thmu.load_more_handler();

    thmu.load_more_button_visibility();

    thmu.search_save_position();
    thmu.load_more_scroll(true);

    thmu.infiniteScroll.init();

    thmu.social();
  });

  $(window).on("scroll", function () {
    thmu.load_more_scroll();
  });

  thmu.init = function () {
    // loading

    $('iframe[allowfullscreen="allowfullscreen"]')
      .addClass("embed-responsive-item")
      .wrap('<div class="embed-responsive embed-responsive-16by9"></div>');

    $("body > .animsition-loading").remove();

    $("#wrapper.loading").removeClass("loading");

    /*$('.meta-info,.meta-list').each(function() {

			$(this).find('a[rel="author"]').attr('rel','no-folow');

		});*/

    $('a[rel="no-folow"]').on("click", function () {
      return false;
    });

    $(".info-area .results-list li a br, .tags-section li a br").remove();

    $("#back-btn").on("click", function () {
      window.history.back();
    });

    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
    });

    $(".footnote-ref a").on("click", function () {
      var hash = $(this).attr("href");
      $(".footnotes-info-wrapper li" + hash)
        .addClass("active")
        .siblings("li")
        .removeClass("active");
      goToHash(hash, $("#header").outerHeight());
      return false;
    });

    $("body.search").on("click", ".tags-section ul .active a", function () {
      $('.bootstrap-tagsinput input[type="text"]').val($(this).text());

      var search = thmu.get_url_param("s");
      if (search) {
        search = decodeURIComponent(search) + "," + $(this).text();
      } else {
        search = $(this).text();
      }

      thmu.set_taginput_val($("#search-tagsinput"), search);

      return false;
    });

    var $book_container = $(".book-container");
    if ($book_container.length) {
      var $articles_next = $book_container.next(".article-area");
      if ($articles_next.length) {
        var inner = $.trim($articles_next.find(".container-fluid").html());
        if (inner === '<div id="quotable-content"></div>') {
          // empty section
          $articles_next.hide();
        }
      }
    }

    var $checked_container = $("body.single .article-area .container-fluid");
    if ($checked_container.length && !$.trim($checked_container.html())) {
      $checked_container.closest(".article-area").remove();
    }

    var $checked_container = $(
      "body.single .article-area .container-fluid .lead"
    );
    if ($checked_container.length && !$.trim($checked_container.html())) {
      $checked_container.closest(".article-area").remove();
    }
  };

  thmu.load_more_scroll = function (init) {
    var factor_init = init ? 1 : 2;
    var factor_height = 2;
    if (parseInt(thmu.get_url_param("loaded_pages")) > 3) {
      factor_height = 1.2;
    } else if (parseInt(thmu.get_url_param("loaded_pages")) > 5) {
      factor_height = 1.5;
    } else if (parseInt(thmu.get_url_param("loaded_pages")) > 10) {
      factor_height = 1.8;
    }
    if (
      $(window).scrollTop() + $(window).height() / factor_init >
      $(".wrapper").height() / factor_height
    ) {
      //if ($(window).scrollTop() >= $(document).height() - $(window).height()) {
      if ($("#load-more").length && !$("#load-more").hasClass("stop-load")) {
        $("#load-more").addClass("stop-load");
        $("#load-more")[0].click();
      }
    }
  };

  thmu.search_handler = function () {
    var $input_ = $("#search-tagsinput");
    if (!$input_.length) {
      return;
    }

    $(".tags-section li").addClass("active");
    $(".tags-section li a").on("click", function () {
      var $li = $(this).closest("li");
      if ($li.hasClass("selected")) {
        $li.removeClass("selected");
        $(".tags-section li").addClass("active");
        window.history.replaceState(
          "",
          "",
          window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?s=" +
            encodeURIComponent($input_.val())
        );
      } else {
        $(".tags-section li").removeClass("active");
        $(".tags-section li").removeClass("selected");
        $li.addClass("active");
        $li.addClass("selected");
      }
      thmu.get_search_data($input_);
      return false;
    });

    $(".tags-section ul").each(function () {
      var id = $(this).attr("id");
      var id_par = thmu.get_url_param(id);
      if (id_par) {
        var $a = $("#" + id).find('li a[data-slug="' + id_par + '"]');
        if ($a.length) {
          $(".tags-section ul li").removeClass("active");
          $(".tags-section ul li").removeClass("selected");
          $a.closest("li").addClass("active");
          $a.closest("li").addClass("selected");
          thmu.get_search_data($input_);
          return false;
        }
      }
    });

    // set init value

    var search = thmu.get_url_param("s");
    var selection = thmu.get_url_param("selection");
    if (search || selection) {
      if (search) {
        thmu.set_taginput_val($input_, decodeURIComponent(search));
      }
      thmu.get_search_data($input_);
    } else {
      // empty search parametr
      //$('.tags-section li').addClass('active');
      $(".bootstrap-tagsinput").find('input[type="text"]').focus();
      $(".results-section .container-fluid").html($("#enter_key").html());
    }

    // change
    $input_.on("change", function () {
      var val = $(this).val();

      if (val) {
        var arr_vals = val.split(",");

        if (arr_vals.length) {
          var alert = false;

          for (var i = arr_vals.length - 1; i >= 0; i--) {
            if (arr_vals[i].length <= 1) {
              alert = true;

              arr_vals.splice(i, 1);
            }
          }

          if (alert) {
            var new_val = arr_vals.join(",");

            thmu.set_taginput_val($(this), new_val);

            $("#min_length").fadeIn("slow");

            setTimeout(function () {
              $("#min_length").fadeOut("slow");
            }, 3000);

            //min_length
          }
        }
      }

      thmu.get_search_data($(this));

      var id_search = "";
      var term_search = "";
      var $selected = $(".tags-section li.selected");
      if ($selected.length) {
        var id_search = $selected.closest("ul").attr("id");
        var term_search = $selected.find("a").attr("data-slug");
        var s_p = thmu.get_url_param("s");
        s_p = s_p ? s_p : "";
      }

      if (id_search && term_search) {
        window.history.replaceState(
          "",
          "",
          window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?s=" +
            encodeURIComponent($(this).val()) +
            "&" +
            id_search +
            "=" +
            term_search
        );
      } else {
        window.history.replaceState(
          "",
          "",
          window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?s=" +
            encodeURIComponent($(this).val())
        );
      }
    });
  };

  thmu.get_search_data = function ($el) {
    $(".tags-section li a").each(function () {
      var href = $(this).attr("href");
      var parameter = "search";
      if (thmu.get_url_param(parameter, href)) {
        var new_url = thmu.removeURLParameter(href, parameter);
        $(this).attr("href", new_url);
      }
    });
    var id_search = "";
    var term_search = "";
    var $selected = $(".tags-section li.selected");
    if ($selected.length) {
      var id_search = $selected.closest("ul").attr("id");
      var term_search = $selected.find("a").attr("data-slug");
      var s_p = thmu.get_url_param("s");
      s_p = s_p ? s_p : "";
      window.history.replaceState(
        "",
        "",
        window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?s=" +
          s_p +
          "&" +
          id_search +
          "=" +
          term_search
      );
    }

    var term = $el.val();
    var selection = thmu.get_url_param("selection")
      ? thmu.get_url_param("selection")
      : "";

    if (term || selection || (id_search && term_search)) {
      $(".tags-section").addClass("proccesing");

      $(".tags-section").prepend('<b class="animsition-loading"></b>');

      $(".results-section").addClass("proccesing");

      $.ajax({
        type: "POST",

        url: wp_helper.ajax_url,

        data: {
          action: "theme_load_search_result",
          term: term,
          selection: selection,
          id_search: id_search,
          term_search: term_search,
        },

        success: function (res) {
          if (res.success) {
            if (res.data && !$.isEmptyObject(res.data)) {
              /*if (res.data.years && res.data.years.length) {

								for (var i = res.data.years.length - 1; i >= 0; i--) {

									$el = $('#s_years [data-year="' + res.data.years[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};

							if (res.data.location && res.data.location.length) {

								for (var i = res.data.location.length - 1; i >= 0; i--) {

									$el = $('#s_location [data-term="' + res.data.location[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};

							if (res.data.programme_categories && res.data.programme_categories.length) {

								for (var i = res.data.programme_categories.length - 1; i >= 0; i--) {

									$el = $('#s_programme [data-term="' + res.data.programme_categories[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};

							if (res.data.library_categories && res.data.library_categories.length) {

								for (var i = res.data.library_categories.length - 1; i >= 0; i--) {

									$el = $('#s_library [data-term="' + res.data.library_categories[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};

							if (res.data.cats && res.data.cats.length) {

								for (var i = res.data.cats.length - 1; i >= 0; i--) {

									$el = $('#s_category [data-term="' + res.data.cats[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};

							if (res.data.project && res.data.project.length) {

								for (var i = res.data.project.length - 1; i >= 0; i--) {

									$el = $('#s_project [data-post="' + res.data.project[i] + '"]');

									if ($el.length) {

										$el.closest('li').addClass('active');

										var search_url = thmu.insertParamUrl($el.attr('href'), encodeURIComponent($('#search-tagsinput').val()));

										$el.attr('href', search_url);

									};

								};

							};*/

              $(".results-section .container-fluid").html(res.data.html);

              $(".results-section .results-list h3 br").remove();
            } else {
              // empty search result

              $(".results-section .container-fluid").fadeOut(
                "slow",
                function () {
                  $(".results-section .container-fluid").empty();

                  $(".results-section .container-fluid").html(
                    $("#not_found").html()
                  );

                  $(".results-section .container-fluid").show();

                  //$('.tags-section li').removeClass('active');
                }
              );

              $(".tags-section li a").each(function () {
                var href = $(this).attr("href");

                var parameter = "search";

                if (thmu.get_url_param(parameter, href)) {
                  var new_url = thmu.removeURLParameter(href, parameter);

                  $(this).attr("href", new_url);
                }
              });
            }
          } else {
            alert("Something went wrong. Please try again!");
          }

          $(".tags-section .animsition-loading").remove();

          $(".tags-section").removeClass("proccesing");

          $(".results-section").removeClass("proccesing");
        },

        error: function (jqXHR, exception) {
          thmu.ajax_error_debug(jqXHR, exception);
        },
      });
    } else {
      // empty search input , check selection parametr

      var selection_param = $(".results-section .container-fluid").fadeOut(
        "slow",
        function () {
          $(".results-section .container-fluid").empty();

          $(".results-section .container-fluid").html($("#enter_key").html());

          $(".results-section .container-fluid").show();
        }
      );

      $(".tags-section li a").each(function () {
        var href = $(this).attr("href");

        var parameter = "search";

        if (thmu.get_url_param(parameter, href)) {
          var new_url = thmu.removeURLParameter(href, parameter);

          $(this).attr("href", new_url);
        }
      });
    }
  };

  thmu.inner_search_handler = function () {
    var $input = $("#inner_search");

    if (!$input.length) {
      return;
    }

    // set init value

    var search = thmu.get_url_param("search");

    if (search) {
      thmu.set_taginput_val($input, decodeURIComponent(search));

      thmu.get_search_data($input);
    } else {
      /*$('.tags-section li').addClass('active');

			$('.bootstrap-tagsinput').find('input[type="text"]').focus();

			$('.results-section .container').html($('#enter_key').html());*/
    }

    // change

    $input.on("change", function () {
      if (!$(this).hasClass("proccessing")) {
        $(this).addClass("proccessing");

        setTimeout(function () {
          $input.removeClass("proccessing");
        }, 200);

        var val = $(this).val();

        if (val) {
          var arr_vals = val.split(",");

          if (arr_vals.length) {
            var alert = false;

            for (var i = arr_vals.length - 1; i >= 0; i--) {
              if (arr_vals[i].length <= 1) {
                alert = true;

                arr_vals.splice(i, 1);

                break;
              }
            }

            if (alert) {
              var new_val = arr_vals.join(",");

              thmu.set_taginput_val($(this), new_val);

              $("#min_length").fadeIn("slow");

              setTimeout(function () {
                $("#min_length").fadeOut("slow");
              }, 3000);

              //min_length

              return false;
            } else {
              window.location.href =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "?search=" +
                encodeURIComponent($(this).val());
            }
          }
        } else {
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        }
      }
    });
  };

  thmu.load_more_button_visibility = function () {
    var $btn = $("#load-more");
    if (!$btn.length) {
      return;
    }
    var max_posts = parseInt(wp_helper.max_posts);
    var current_posts = $(".main-area .info-boxes .row > .col-sm-4").length;
    if (max_posts > current_posts) {
      $btn.hide();
    } else {
      $btn.hide();
      $btn.addClass("stop-load");
    }
  };

  thmu.load_more_handler = function () {
    var $btn = $("#load-more");
    if (!$btn.length) {
      return;
    }
    $btn.on("click", function () {
      var next_page = parseInt($(this).attr("data-page")) + 1;
      var post_type = $(this).attr("data-post-type");
      $(this).show();
      var param_events = thmu.get_url_param("events");
      $.ajax({
        type: "POST",
        url: wp_helper.ajax_url,
        data: {
          action: "thmu_load_more",
          query_var: wp_helper.query_vars,
          page: next_page,
          post_type: post_type,
          param_events: param_events,
        },
        success: function (res) {
          $(".main-area .info-boxes .row").append(res);
          heightText();
          $("#load-more").removeClass("stop-load");
          thmu.load_more_button_visibility();
          if (param_events) {
            window.history.replaceState(
              "",
              "",
              window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "?events=" +
                param_events +
                "&loaded_pages=" +
                next_page
            );
          } else {
            window.history.replaceState(
              "",
              "",
              window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "?loaded_pages=" +
                next_page
            );
          }
          $btn.attr("data-page", next_page);
        },
        error: function (jqXHR, exception) {
          thmu.ajax_error_debug(jqXHR, exception);
        },
      });
      return false;
    });
  };

  thmu.set_taginput_val = function ($el, val) {
    $el.tagsinput("destroy");

    $el.val(val);

    $("#search-tagsinput").focus();

    $el.tagsinput("refresh");
  };

  thmu.get_url_param = function (name, url) {
    if (!url) url = location.href;

    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");

    var regexS = "[\\?&]" + name + "=([^&#]*)";

    var regex = new RegExp(regexS);

    var results = regex.exec(url);

    return results == null ? null : results[1];
  };

  thmu.insertParamUrl = function (url, value) {
    return url + "?search=" + value;
  };

  thmu.removeURLParameter = function (url, parameter) {
    var urlparts = url.split("?");

    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + "=";

      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive

      for (var i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");

      return url;
    } else {
      return url;
    }
  };

  thmu.ajax_error_debug = function (jqXHR, exception) {
    if (jqXHR.status === 0) {
      console.log("Not connect.\n Verify Network.");
    } else if (jqXHR.status == 404) {
      console.log("Requested page not found. [404].");
    } else if (jqXHR.status == 500) {
      console.log("Internal Server Error [500].");
    } else if (exception === "parsererror") {
      console.log("Requested JSON parse failed.");
    } else if (exception === "timeout") {
      console.log("Time out error.");
    } else if (exception === "abort") {
      console.log("Ajax request aborted.");
    } else {
      console.log("Uncaught Error.\n" + jqXHR.responseText);
    }
  };

  thmu.search_save_position = function () {
    var count = window.sessionStorage.getItem("thmu_search_position_count");
    if (count == "1") {
      if ($(".results-section").length) {
        window.sessionStorage.removeItem("thmu_search_position");
        window.sessionStorage.removeItem("thmu_search_position_count");
      } else {
        window.sessionStorage.setItem("thmu_search_position_count", "2");
      }
    } else if (count == "2") {
      if ($(".results-section").length) {
        $(document).ajaxComplete(function (event, xhr, settings) {
          if (
            xhr.status == 200 &&
            settings.data.indexOf("action=theme_load_search_result") !== -1
          ) {
            var pos = parseInt(
              window.sessionStorage.getItem("thmu_search_position")
            );
            if (pos) {
              $("html, body").stop().animate(
                {
                  scrollTop: pos,
                },
                300
              );
              window.sessionStorage.removeItem("thmu_search_position");
              window.sessionStorage.removeItem("thmu_search_position_count");
            }
          }
        });
        setTimeout(function () {
          window.sessionStorage.removeItem("thmu_search_position");
          window.sessionStorage.removeItem("thmu_search_position_count");
        }, 5000);
      } else {
        window.sessionStorage.removeItem("thmu_search_position");
        window.sessionStorage.removeItem("thmu_search_position_count");
      }
    }
    $(".results-section").on("click", "li a", function () {
      var position = $(window).scrollTop();
      window.sessionStorage.setItem("thmu_search_position", position);
      window.sessionStorage.setItem("thmu_search_position_count", "1");
    });
  };

  function goToHash(hash, inc) {
    var offsetTop;
    if ($.isValidSelector(hash) && $(hash).length) {
      offsetTop = $(hash).offset().top;

      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: offsetTop - inc,
          },
          200
        );
      return false;
    }
  }
  $.extend({
    isValidSelector: function (selector) {
      if (typeof selector !== "string") {
        return false;
      }
      try {
        var $element = $(selector);
      } catch (error) {
        return false;
      }
      return true;
    },
  });

  function heightText() {
    $(".info-box").each(function (index, el) {
      var heightParent = $(this).outerHeight(),
        lineHeightEl = parseInt($(this).find(".block p").css("line-height")),
        padding =
          $(this).find(".block").outerHeight() -
          $(this).find(".block").height();
      (titleHeight = $(this).find("h2").outerHeight(true)),
        (metaHeight = $(this).find(".meta-info").outerHeight());
      (result = heightParent - titleHeight - metaHeight - padding),
        (remainder = result % lineHeightEl),
        (resultHeught = result - remainder);

      $(this).find(".block p").height(resultHeught);
    });
  }

  thmu.infiniteScroll = {};
  thmu.infiniteScroll.process = false;
  thmu.infiniteScroll.loader =
    '<div class="wrap_fountainG"><div class ="fountainG_0"><div class="fountainG fountainG_1"></div><div class="fountainG fountainG_2"></div><div class="fountainG fountainG_3"></div></div></div><!-- end link-section -->';
  thmu.infiniteScroll.loader_selector = ".wrap_fountainG";
  thmu.infiniteScroll.init = function () {
    var $paged = $(".pagination-scroll-elemnt");
    if (!$paged.length) {
      return;
    }
    this.$el = $paged;
    var fire = this.check();
    if (fire) {
      this.load();
    }
    $(window).on("scroll", function () {
      var fire = thmu.infiniteScroll.check();
      if (fire) {
        thmu.infiniteScroll.load();
      }
    });
  };
  thmu.infiniteScroll.check = function () {
    var to_end = 6000;
    return (
      $(document).height() - $(window).height() <=
      $(window).scrollTop() + to_end
    );
  };
  thmu.infiniteScroll.load = function () {
    if (this.process) {
      return;
    }
    this.process = true;
    var data = this.$el.data();
    var $that = this.$el;
    // fix data object after ajax
    data.current = parseInt(this.$el.attr("data-current"));
    if (data.current >= data.max) {
      return;
    } else {
      var next = data.current + 1;
      if (data.baseUrl.indexOf("?s=") !== -1) {
        // is search
        var search_param = data.baseUrl.split("?")[1];
        var url = wp_helper.home_url + "/page/" + next + "/?" + search_param;
      } else {
        var url = data.baseUrl + "page/" + next + "/";
      }
      $(data.selector).after(thmu.infiniteScroll.loader);
      $.ajax({
        type: "GET",
        url: url,
        success: function (res) {
          $(thmu.infiniteScroll.loader_selector).remove();
          var posts = $(res).find(data.selector).html();
          // save position
          data.position = $(window).scrollTop();
          $(data.selector).append(posts);
          // restore position
          $(window).scrollTop(data.position);
          $that.attr("data-current", next);
          thmu.infiniteScroll.process = false;
          // check again
          var fire = thmu.infiniteScroll.check();
          if (fire) {
            thmu.infiniteScroll.load();
          }
        },
        error: function (jqXHR, exception) {
          console.log(jqXHR);
        },
      });
    }
  };

  thmu.social = function () {
    if ($(".tweet-post").length) {
      var html = $(".tweet-post").html();
      var output = "<header>";
      var author_link = $(html).find(".ctf-author-name:first").attr("href");
      var author_name = $(html).find(".ctf-author-name:first").text();
      var screenname = $(html).find(".ctf-author-screenname:first").text();
      var date = $(html).find(".ctf-tweet-date:first").text();
      var text = $(html).find(".ctf-tweet-text:first").html();
      output +=
        '<h3><a target="_blank" href="' +
        author_link +
        '">' +
        author_name +
        "</a></h3>";
      if (screenname) {
        output +=
          '<span class="str"><a target="_blank" href="' +
          author_link +
          '">' +
          screenname +
          "</a></span>";
      }
      if (date) {
        output += "<time>" + date + "</time>";
      }
      if (text) {
        output +=
          '<p><a target="_blank" href="' +
          author_link +
          '">' +
          text +
          "</a></p>";
      }
      output += "</header>";
      $(".tweet-post").html(output);
    }
    if ($(".instagram-post").length) {
      $(window).on("load", function () {
        var output = "";
        var html = $(".instagram-post").html();
        var photo = $(html).find("a.sbi_photo:first img").attr("src");
        var photo_href = $(html).find("a.sbi_photo:first").attr("href");
        var alt = $(html).find(".sbi_photo:first img").attr("alt");
        var text = $(html).find(".sbi_photo:first img").attr("alt");
        var text_to_hastag = text;
        text_to_hastag = text_to_hastag.replace(
          /(^|\s)(#[a-z\d-]+)/gi,
          "$1<span class='hash_tag'>$2</span>"
        );

        var hastags = [];
        $(text_to_hastag).each(function () {
          hastags.push($(this).text());
        });

        var author_link = $(html).find(".sbi_follow_btn a").attr("href");
        var author_text = $(html).find(".sbi_header_text h3").text();
        var output = "";
        if (photo) {
          output +=
            '<div class="instagram-img"><a target="_blank" href="' +
            photo_href +
            '"><img src="' +
            photo +
            '" alt="' +
            alt +
            '"></a></div>';
        }
        output += '<figcaption class="descr">';
        output +=
          '<h3><a target="_blank" href="' +
          author_link +
          '">' +
          author_text +
          "</a></h3>";
        output += "<p>" + alt + "</p>";
        if (hastags.length) {
          output += '<ul class="instagram-tags">';
          for (var i = hastags.length - 1; i >= 0; i--) {
            output += "<li>" + hastags[i] + "</li>";
          }
          output += "</ul>";
        }
        output += "</figcaption>";
        $(".instagram-post").html(output);
        if (window.theatrum.msnry) {
          window.theatrum.msnry.layout();
        }
      });
    }
  };
})(jQuery);
